@import "../../style/variable";
@import "../../style/mixins";

.container {
  // min-height: 100vh;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.gif-page {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // height: 100vh;
  height: calc(100vh - 15vh);

  .gif-page-content {
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 100%;

    .child{
      height: 100%;
    }
    .first-col {
      text-align: center;
    }

    .second-col {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      .main-title {
        margin-bottom: 10px;
        font-size: 38px;
      }

      .content {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;

        p {
          margin-bottom: 0;
          // @include content-style;
        }
      }

      .gita {
        @include content-style;
        text-align:center;
        font-size: 16;
        margin-top: 15px;
      }
    }
  }

  .gif-block {
    // margin-bottom: 40px;
    height: 50%;
    position: relative;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    .content {
      text-align: center;
    }

    .gif-image {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        // height: 212px;
        width: 100%;
        object-fit: cover;
        object-position: center;

        &:hover {
          cursor: pointer;
        }
      }
    }

    // .image-1 {
    //   top: 40px;
    // }

    // .image-2 {
    //   bottom: 40px;
    // }

    // .image-3 {
    //   top: 40px;
    // }

    // .image-4 {
    //   bottom: 40px;
    // }
  }
}

@media (max-width: 992px) {
  .gif-page {
    .gif-page-content {
      .second-col {
        .main-title {
          font-size: 34px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .gif-page {
    .gif-page-content {
      .second-col {
        .main-title {
          font-size: 24px;
          margin-bottom: 5px;
        }

        .content {
          font-size: 12px;
        }
        .gita{
          font-size: 10px;
          margin-top: 10px;
        }
      }
    }

    .gif-block {

      // .image-3, .image-1{
      //   top: 35px ;
      // }
      .gif-image {
        img {
          height: 174px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .gif-page {
    padding: 40px 0;
    margin: 0;
    .gif-page-content{
      overflow:inherit ;
      overflow-x: hidden;
      .child{
        height: unset;
      }
      .second-col{
        .gita{
          font-size: 14px;
        }
      }
    }

    .gif-block {
      background: $white;
      padding: 15px;
      border-radius: 5px;
      box-shadow: $blockShadow;
      margin-bottom: 17px;
      height: unset;

      &:nth-last-child(1) {
        margin-bottom: 17px;
      }

      
      .gif-image {
        position: unset;

        img {
          height: 230px;
          // margin-bottom: 15px;
          width: -webkit-fill-available;
        }
      }

      .content {
        text-align: left;
      }
    }
  
    .second-col {
      margin: 30px 0;

      .second-col-content {
        .content {
          font-size: 18px;
        }

      }
      
    }
  }
}

@media (max-width: 375px) {
  .gif-page {
    .gif-block {
      .gif-image {
        img {
          height: 220px;
          margin-bottom: 0;
        }
      }
    }

    .second-col {
      margin: 20px 0;
      .second-col-content {
        .content {
          font-size: 16px;
        }
        .gita{
          font-size: 12px;
        }
      }
    }
  }
}


@media (max-width: 320px) {
  .gif-page {
    .second-col {
      .second-col-content {
        .content {
          font-size: 15px;
        }
      }
    }
  }
}

@media (max-width: 1930px) and (min-width: 1650px) {
  .container {
    min-width: 1600px;
  }

  .gif-page {

    // .gif-block {
    //   .gif-image {
    //     img {
    //       height: 200px;
    //     }
    //   }
    // }
    .gif-block {
      .image-1 {
        top: 80px;
      }

      .image-2 {
        bottom: 80px;
      }

      .image-3 {
        top: 80px;
      }

      .image-4 {
        bottom: 80px;
      }
    }
  }

}

// @media (max-width: 1930px) and (min-width: 1650px) {
//   .gif-page {
//     .gif-block {
//       .gif-image {
//         img {
//           height: 200px;
//         }
//       }
//     }
//   }
// }