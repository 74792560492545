@import "../style/variable.scss";
@import "../style/main.scss";

.work-info {
  .work-list {
    padding: 35px;
    height: 500px;
    margin-top: 20px;
    .main-title {
      font-size: 50px;
      color: $darkgreen;
      font-weight: 600;
    }

    .d-flex {
      justify-content: center;
      height: 100px;

      .info-content {
        padding: 10px;

        .content {
          text-align: initial;
          font-size: 23px;
          margin-top: 10px;
          line-height: 1.3;
        }
      }
    }
  }

  .safe {
    img {
      width: 100%;
      height: 600px;
      object-fit: cover;
    }
  }
}
@media (max-width: 992px) {
  .safe {
    img {
      height: 500px !important;
      object-fit: contain !important;
    }
  }
  .work-list {
    .mb-30 {
      margin-bottom: 18px;
    }
    .ml-25 {
      margin-left: 0%;

      .info-content {
        .content {
          font-size: 19px !important;
          margin-top: 5px;
        }
      }
    }
    .ml-30 {
      margin-left: 0;

      .content {
        font-size: 13px;
      }

      .circle {
        padding: 10px 26px;
        font-size: 49px;
        height: 80px;
      }
    }
  }
}

@media (max-width: 768px) {
  .work-info {
    margin-top: -68px;
    .section-gap {
      padding: 0 !important;
    }
    .work-list {
      margin-top: 60px;
      .mb-30 {
        margin-bottom: 0;
      }
      .main-title {
        font-size: 38px;
      }
      .ml-25 {
        .info-content {
          .content {
            margin-top: -4px;
            line-height: 1.3;
            font-size: 16px !important;
          }
        }
      }

      .circle {
        padding: 10px 24px;
        font-size: 42px;
        height: 75px;
      }
    }
  }
  .circle {
    padding: 5px 26px;
    font-size: 49px;
    height: 77px;
  }
}

@media (max-width: 480px) {
  .work-info {
    .safe {
      margin-top: -20%;
    }
    .work-list {
      .d-flex {
        .info-content {
          margin-top: 18px;
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .work-info {
    .work-list {
      .main-title {
        font-size: 35px;
      }

      .info-content {
        .content {
          margin-top: -15px;
          font-size: 17px;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .work-info {
    .work-list {
      .ml-25 {
        .info-content {
          .content {
            margin-top: -23px;
            font-size: 15px !important;
          }
        }
      }
      .main-title {
        font-size: 31px;
      }

      .circle {
        padding: 10px 28px;
        font-size: 42px;
        height: 73px;
      }
    }
  }
}

@media (max-width: 320px) {
  .work-info .safe {
    margin-top: -60%;
  }
}
