.card {
  margin: 100px;
  padding: 10px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.required {
  color: red;
}
.tab-content {
  padding: 40px;

  .select {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .select-l {
    margin: 10px 40px 0 10px;
    input {
      margin-right: 10px;
    }
  }

 
  .custom-select {
    height: 50px;
    padding: 10px;
  }
  .input-c {
    padding: 10px;
    width: 100%;
    margin-top: 30px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    height: 50px;
  }

  .checkbox {
    margin-top: 20px;
    input {
      margin-right: 15px;
    }
  }

  .btn-add {
    padding: 11px 30px;
    border-radius: 45px;
  }
}



.custom-form{
  .section-card{
    padding: 40px;
  }
  .condition{
    display:flex;
    align-items: baseline;
    input{
      margin-right: 7px;
    }
  }
  .btn-add{
    margin-top: 30px;
  }
  .child{
    margin-bottom: 15px;
    &:nth-last-child(1){
      margin-bottom: 0;
    }
    select{
      text-transform: capitalize;
    }
  }
}
@media(max-width:768px){
  .custom-form{

    .btn-add{
      font-size: 12px;
    }
  }
}
@media(max-width:480px){
  .custom-form{
    .section-card{
      padding: 30px;
    }
    .btn-add{
      margin-top: 15px;
    }
  }

  .tab-content{
    padding: 30px 15px;
  }
}

@media(max-width:376px){
  .custom-form{
    .section-card{
      padding: 20px;
    }
   
  }

  .tab-content{
    padding: 15px 0;
  }
}