@import "../style/variable.scss";
.Navbar-content {
  .nav-item {
    margin-left: 45px;
  }

  .nav-link {
    color: $primarycolor !important;
    font-weight: 600;
    font-size: 16px;
    padding: 10px 25px;
    width: 100%;
  }

  .nav-link:hover {
    border-radius: 20px;
    color: $white !important;
    background-color: #00af97;
    padding: 8px 34px;
  }
}

.bg-light {
  background-color: $white;
  height: 90px;
}
