@import "../style/variable.scss";
@import "../style/main.scss";

.footer {
  height: 300px;
  margin-bottom: 15px;
  padding: 30px;
  background: linear-gradient(
    0deg,
    rgba(0, 67, 66, 1) 4%,
    rgba(0, 74, 75, 1) 25%,
    rgba(0, 97, 96, 1) 42%,
    rgba(0, 106, 103, 1) 63%,
    rgba(0, 121, 117, 1) 100%
  );
}

@media (max-width: 480px) {
  .footer {
    height: 390px;
  }
}
