@import "../../style/variable";
@import "../../style/mixins";

.emergency-contact {
    .section-card {
        padding: 0;
    }

    .main-title {
        text-align: left;
    }

    .country {
        color: $primarycolor;
        text-transform: uppercase;
        margin: 40px 0 20px;
        font-size: 22px;
    }

    .emergency-area {
        padding: 20px;

    }

    .search-block {
        margin: 20px 0;
    }

    .emergency-section {
        .child {
            margin-bottom: 15px;

            &:nth-last-child(-n+2) {
                margin-bottom: 0;
            }

            &:nth-child(odd) {
                padding-right: 7px;
            }

            &:nth-child(even) {
                padding-left: 7px;
            }
        }

        .emergency-block {
            background: $lighbg;
            border-radius: 5px;
            padding: 15px;

            .emergency-title {
                font-size: 14px;
                margin: 0;
                font-weight: 600;
                color: $primarycolor;
                text-transform: uppercase;
            }

            .emergency-number {
                font-size: 16px;
                margin: 8px 0 0;
                color: $contentColor;
                font-weight: 600;
            }
        }
        .pagination{
margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            .MuiPaginationItem-page.Mui-selected{
                background-color: $primarycolor ;
                color: $white;
            }
        }
    }

    .emergency-side-info {
        background: $primarycolor;

        border-radius: 0 5px 5px 0;
        height: 100%;

        .bold {
            font-weight: 500 !important;
            margin-top: 5px;
        }

        .content {
            color: $white;
            font-weight: 400;
        }

        .info-contact {
            padding: 15px 20px;
            border-bottom: 1px solid $white;

            .info-nummber {
                margin-bottom: 15px;

                &:nth-last-child(1) {
                    margin-bottom: 0;
                }
            }

        }

        .emergency-links {
            padding: 15px 20px;

            .title {
                color: $white;
                margin-bottom: 15px;
                margin-top: 0;
            }
            .content{
                margin-bottom: 5px;
                word-break: break-all;
                &:nth-last-child(1){
                    margin-bottom: 0;
                }
            }
        }

    }
}