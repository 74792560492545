@import "../style/variable.scss";
@import "../style/main.scss";

.slider {
  .slick-slide img {
    display: block;
    height: 600px;
    width: 100%;
    object-fit: cover;
    position: relative;
    overflow-y: hidden;
  }
  .slick-dots {
    display: block;
    color: white;
    position: absolute;
    top: 536px;
    left: 310px;
  }

  .clippy {
    background-color: red;
    clip-path: polygon(
      31% 6%,
      30% 88%,
      39% 99%,
      54% 100%,
      47% 83%,
      45% 19%,
      44% 15%,
      37% 7%
    );
  }

  .slick-dots li button:before {
    font-size: 10px;
  }
}

.bg-col {
  background-color: #f4f4f4;
}

.pop {
}

@media (max-width: 992px) {
  .slider {
    img {
      height: 382px !important;
    }
  }
}

@media (max-width: 768px) {
  .slider {
    .block-content {
      .main-title {
        font-size: 33px;
        padding: 90px 73px 0px 20px;
      }
      .btn-add {
        margin: 10% 0 0 10%;
        width: 115px;
        font-size: 14px;
      }
      .cap {
        font-size: 15px;
      }
    }
  }
}
@media (max-width: 480px) {
  .slider {
    .block-content {
      .main-title {
        font-size: 40px;
        padding: 90px 128px 0px 20px;
      }
      .btn-add {
        margin: 4% 0 0 13%;
        width: 165px;
      }
    }
  }
}

@media (max-width: 360px) {
  .slider {
    .block-content {
      .main-title {
        font-size: 24px;
      }

      .d-flex {
        flex-direction: column;

        .btn-add {
          width: 80%;
          padding: 10px;
        }
      }
    }
  }
}
@media (max-width: 320px) {
  .slider {
    .block-content {
      .main-title {
        padding: 90px 80px 0px 0px;
      }
    }
  }
}
