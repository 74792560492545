$primarycolor: #00af97;
$white: #fff;
$lightContentColor: #555555cf;
$titleColor: #333333;
$contentColor: #555555;
$red: rgb(180, 32, 32);
$lightgreen: #00d1b3;
$darkgreen: #00797a;
$medigreen: #00af97;
$shadow:  #66666633 0px 4px 30px -1px, #0000000f 0px 2px 4px -1px ;
$blockShadow:0 1px 6px #adadad40;
$borderColor:#a5a5a5a6;
$borderRadius:5px;
$lighbg:#f6f6f6a1;

