@mixin content-style {
  font-size: 16px;
  font-weight: 500;
  color: $contentColor;
  margin: 0;
  line-height: 1.7;
}

@mixin title-style {
  font-size: 18px;
  font-weight: 600;
  color: $titleColor;
  margin: 0;
}

@mixin  content-style {
  font-size: 16px;
  font-weight: 500;
  color: $contentColor;
  margin: 0;
}
@mixin input-style {
  border: 1px solid $borderColor;
  padding: 5px 10px;
  border-radius: $borderRadius;
  font-size: 15px;
  color: $titleColor;
  width: 100%;
  height: unset;
  font-size: 14px;

  &:focus {
      outline: unset;
  }
  
}
