.modal-body-block {
  border-radius: 5px;

  .close-btn {
    display: flex;
    justify-content: flex-end;

    .cross-btn {
      font-size: 20px;
    }
  }

  .banner-img {
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      border-radius: 5px;
    }
  }
}

.home-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: calc(100vh - 200px);
  width: 100%;
  top: 15px;
  left: 0;
  position: relative;

  .homePage-content {
    padding: 50px 30px;
    text-align: center;
  }

  .main-title {
    font-size: 40px;
    margin: 0;
    font-weight: 700;
  }

  .content {
    margin: 30px 0px;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.3;
  }

  .p-content {
    margin-bottom: 0;
  }

  .start-btn {
    .btn-primary {
      font-size: 20px;
      font-weight: 600;
      background-color: #138b89;
      border-color: #138b89;
    }
  }
}

.nav-bar-content {
  display: flex;
  justify-content: space-between;

  .navbar-logo {
    img {
      width: 200px;
      margin-top: 20px;
      height: 50px;
      object-fit: cover;
    }
  }

  .nav-link-content {
    .nav-link {
      color: #138b89;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 600;

      &:active {
        background: transparent;
      }
    }

  }
}

.footer-tab {
  margin-top: 30px;

  p {
    font-weight: 500;
    margin-bottom: 15px;
    width: 100%;
  }

  .footer-logo {
    img {
      width: 120px;
      height: auto;
      object-fit: cover;
      padding: 0 5px;
      margin-bottom: 10px;
    }
  }

  .footer-content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: flex-end;

    .footer-logo {
      img {
        width: 120px;
        height: auto;
        object-fit: cover;
        padding: 0 5px;
        margin-bottom: 10px;
      }
    }

    .social {
      margin-top: 0;
      display: flex;

      svg {
        margin: 0 5px;
        color: #b5b5b5;
        font-size: 1.4rem;
      }

      .facebook {
        padding: 7px 6px 0 4px;
        border: none;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: -2px 1px 10px 5px whitesmoke;
        margin: 0 5px;
        height: 40px;
        width: 40px;
      }

      .facebook:hover {
        background-color: #138b89;
        color: white;
        box-shadow: -2px 1px 10px 5px whitesmoke;
      }

      .facebook:hover svg {
        color: #fff !important;
      }
    }
  }
}

// @media(max-width: 992px){
//   .home-page{
//     .main-title{
//       // font-size: 32px;
//     }

//   }
// }
.v-h-d {
  visibility: hidden;
}

.v-h {
  visibility: hidden;
}

@media (max-width: 768px) {
  .home-page {
    .main-title {
      font-size: 30px;
    }

    .content {
      font-size: 17px;
      margin: 20px 0px;
    }

    .homePage-content {
      padding: 30px;
    }
  }

  .footer-tab {
    .footer-content {
      .footer-logo {
        img {
          width: 147px;
          height: 54px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .navbar-logo {
    text-align: center;
  }

  .home-page {
    min-height: calc(100vh - 280px);

    .main-title {
      font-size: 20px;
    }

    .content {
      font-size: 12px;
    }

    .homePage-content {
      padding: 10px;
    }

    .start-btn {
      .btn-primary {
        font-size: 16px;
      }
    }
  }

  .footer-tab {
    .footer-content {
      display: block;
      margin-bottom: 5px;

      .social {
        margin: 20px;
        margin-bottom: 0;
        text-align: center;
        padding-bottom: 20px;
        justify-content: center;
      }

      .footer-logo {
        display: flex;
        justify-content: space-between;

        img {
          width: 137px;
          height: 47px;
        }
      }
    }
  }

  .v-h-d {
    display: none;
  }
  .mt-15 {
    margin-top: 15px;
  }
}

@media (max-width: 375px) {
  .home-page {
    .homePage-content {
      padding: 30px 15px;
    }

    .main-title {
      font-size: 20px;
    }

    .content {
      margin: 15px 13px;
    }

    .start-btn .btn-primary {
      font-size: 14px;
    }
  }

  .footer-tab .footer-content .footer-logo img {
    width: 133px;
    height: 48px;
  }

  .v-h-d {
    display: none;
  }
}

@media (max-width: 320px) {
  .home-page {
    min-height: calc(100vh - 270px);

    .main-title {
      font-size: 18px;
    }

    .content {
      margin: 15px 0;
      font-size: 10px;
    }
  }

  .footer-tab .footer-content .footer-logo img {
    width: 112px;
    height: 38px;
  }

  .v-h-d {
    display: none;
  }
}