.food-supplies {
  margin: 50px 0 0 0;
  padding: 25px;
  .text-name {
    margin-bottom: 10px;
  }
  .text {
    padding-bottom: 3px;
    text-transform: uppercase;
    font-weight: 400;
  }
  .bg-color-primary {
    background-color: #138b89;
    padding: 20px;
    border-radius: 5px;

    h5,
    p {
      color: white;
      margin: 0px;
      a {
        text-decoration: none;
        color: #fff;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
.mb-2 {
  margin-bottom: 20px;
}
.gallery {
  margin: 50px 0 0 0;
  .p-gallery {
    padding: 25px;
  }
  .p-video {
    padding: 25px 13px 13px 13px;
  }
}
.react-photo-gallery--gallery {
  img {
    object-fit: cover;
  }
}
.title-gap {
  margin-top: 50px;
  margin-bottom: 0;
}
img.header-underline {
  height: 5px;
  width: auto;
}
.mt-25 {
  margin-top: 25px;
}